import { useMemo } from "react";

import { coerceDateScalar } from "../lib/coerceTimeScalars";

const useFormattedDate = (date: Date | null | undefined) => {
  return useMemo(() => {
    if (!date) {
      return "";
    }

    const coercedDate = coerceDateScalar(date);
    const dt = new Date(coercedDate);

    return `${
      dt.getUTCMonth() + 1
    }/${
      dt.getUTCDate()
    }/${
      dt.getUTCFullYear()
    }`;
  }, [date]);
};

export default useFormattedDate;
