import { FC, useMemo } from "react";
import SelectField, { SelectFieldProps } from "./Form/SelectField";

import { useCaseResultTypes } from "../../hooks/caseTypeHooks";

export const CaseResultTypeSelect: FC<SelectFieldProps> = ({
  name,
  label,
  required = false,
  id,
  autoComplete,
}) => {
  const caseResultTypes = useCaseResultTypes();

  const options = useMemo(() => [
    {
      value: "",
      display: <>&mdash;</>,
    },
    ...(caseResultTypes || []).map((m) => ({
      value: m.id,
      display: m.name,
    })),
  ], [caseResultTypes]);

  if (!caseResultTypes) return null;

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} emptyAsNull />;
};
