import { FC, useMemo } from "react";
import SelectField, { SelectFieldProps } from "./SelectField";
import { useCurrentOrgMembers } from "@reasongcp/react-fire-sub";

export const UserSelect: FC<SelectFieldProps> = ({
  name,
  label,
  required = false,
  id,
  autoComplete,
}) => {
  const members = useCurrentOrgMembers();

  const options = useMemo(() => members.map((m) => ({
    value: m.uid,
    display: m.displayName,
  })), [members]);

  if (options.length === 0) return null;

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

const AssigneeSelect: FC<Omit<SelectFieldProps, "name" | "label">> =
  (props) => (
    <UserSelect {...props} name="assigneeUid" label="Assignee" />
  );

export default AssigneeSelect;
