import React, { FC } from "react";
import { Grid, Box, Paper } from "@mui/material";

import { useCases } from "../../../hooks/cases";
import { DataGrid } from "../../shared/DataGrid";
import { caseColumns, useCaseColumnVisibilityModelProps } from "./dataGridColumns";

const AllCases: FC = () => {
  const { cases } = useCases();
  const caseColumnVisibilityModelProps = useCaseColumnVisibilityModelProps();
  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs lg={12}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <DataGrid
              title="Cases"
              rows={cases}
              columns={caseColumns}
              settingsPrefix="cases"
              {...caseColumnVisibilityModelProps}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllCases;
