/* ### WARNING ###
|* This file is duplicated in auditaware-ui/src/compononents/Formik/SelectField.tsx.
|* @TODO: This file will be removed in the future, but in the mean time, if
|* you change |* this, you may need to change the other file as well.
\*/

import { FC, ReactNode, useCallback } from "react";
import { useField } from "formik";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface MenuItemProps {
  value: string;
  display: ReactNode;
}

export interface SelectFieldProps {
  name: string;
  label: string;
  required?: boolean;
  id?: string;
  type?: string;
  autoComplete?: string;
  options?: string[] | MenuItemProps[];
  disabled?: boolean;
  // defaultSelectValue is used as the value for the select element when the
  // field's value is null, as null in a value prop raises an error in React.
  defaultSelectValue?: string;
  emptyAsNull?: boolean;
}

const isMenuItemProps = (
  x: string[] | MenuItemProps[] | undefined
): x is MenuItemProps[] => {
  if (!x) return false;

  const first = x[0];
  if (!first) return false;
  return (first as MenuItemProps).display !== undefined;
};

const SelectField: FC<SelectFieldProps> = ({
  name,
  required = false,
  id,
  label,
  autoComplete,
  disabled,
  options = [],
  defaultSelectValue = "",
  emptyAsNull = false,
}) => {
  const [field, meta, { setValue }] = useField(name);
  const { onBlur } = field;

  const onChange = useCallback(
    (e: SelectChangeEvent<{ value: unknown }>) => {
      const { value } = e.target;
      const safeValue = value === "" && emptyAsNull ? null : value;
      setValue(safeValue);
    },
    [setValue, emptyAsNull]
  );

  const error: boolean = meta.touched && Boolean(meta.error);
  const value = field.value === null ? defaultSelectValue : field.value;

  const props = {
    value: value || "",
    required,
    id,
    label,
    name,
    autoComplete,
    autoWidth: true,
    error,
    onChange,
    onBlur,
    disabled,
  };

  const isComplexOptions = isMenuItemProps(options);

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel>{label}</InputLabel>
      <Select {...props}>
        {isComplexOptions ?
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          )) :
          options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={true}>{meta.error}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
