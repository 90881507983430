import { FC } from "react";
import { useFormikContext, Form, Field, FieldProps } from "formik";
import { Box, Button, Chip, Divider, Grid } from "@mui/material";

import { TextField, DecimalField, CheckboxField } from "auditaware-ui";
import { Case, CaseStatus, Subject, caseStatusNames } from "auditaware-types";

import SelectField from "../../shared/Form/SelectField";
import CaseTypeSelect from "../../shared/Form/CaseTypeSelect";
import UsStateSelect from "../../shared/Form/UsStateSelect";
import AssigneeSelect, { UserSelect } from "../../shared/Form/AssigneeSelect";
import { CaseResultTypeSelect } from "../../shared/SelectCaseResultType";

type CaseFormProps = {
  closeDrawer: () => void;
  subject: Subject;
};

type ContactFieldsProps = {
  prefix: string;
  title: string;
};

type GridTextFieldProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  type?: string;
  InputLabelProps?: any;
  id?: string;
  xs?: number;
  md?: number;
  lg?: number;
};

const defaultFieldLabel = (name: string) =>
  (name.split(".").pop() as string)
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(/^./, (str) => str.toUpperCase());

const statusOptions = Object.values(CaseStatus).map((value) => ({
  display: caseStatusNames[value],
  value,
}));

const caseCycleOptions = [
  { display: "Annual", value: "Annual" },
  { display: "Quarterly - Q1", value: "Q1" },
  { display: "Quarterly - Q2", value: "Q2" },
  { display: "Quarterly - Q3", value: "Q3" },
  { display: "Quarterly - Q4", value: "Q4" },
];

const GridTextField = ({
  name,
  label = defaultFieldLabel(name),
  id = name.split(".").pop() as string,
  xs,
  md,
  lg,
  ...rest
}: GridTextFieldProps) => (
  <Grid item xs={xs} md={md} lg={lg}>
    <TextField
      id={name}
      name={name}
      label={label}
      {...rest}
      fullWidth
    />
  </Grid>
);

const GridDateField = (props: GridTextFieldProps) => (
  <GridTextField
    {...props}
    type="date"
    InputLabelProps={{ shrink: true }}
  />
);

const SectionDivider = ({ title }: { title: string }) => (
  <Grid item sm={12} my={1}>
    <Divider>
      <Chip label={title} />
    </Divider>
  </Grid>
);

const ContactFields = ({ prefix, title }: ContactFieldsProps) => {
  return (
    <>
      <SectionDivider title={`${title} Information`} />
      <GridTextField
        name={`${prefix}.primaryName`}
        label="First Name"
        xs={12}
        md={6}
      />
      <GridTextField
        name={`${prefix}.secondaryName`}
        label="Last Name"
        xs={12}
        md={6}
      />
      <GridTextField name={`${prefix}.address`} xs={12} />
      <GridTextField name={`${prefix}.city`} xs={12} lg={6} />
      <Grid item xs={12} md={8} lg={4}>
        <UsStateSelect name={`${prefix}.stateAbbr`} />
      </Grid>
      <GridTextField name={`${prefix}.zipCode`} xs={12} md={4} lg={2} />
      <GridTextField name={`${prefix}.phoneNumber`} xs={12} md={6} />
      <GridTextField name={`${prefix}.email`} xs={12} md={6} />
    </>
  );
};

const CaseForm: FC<CaseFormProps> = ({ closeDrawer, subject }) => {
  const { isSubmitting } = useFormikContext<Case>();

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SelectField options={statusOptions} label="Status" name="status" />
        </Grid>
        <Grid item xs={12} md={6}>
          <CaseTypeSelect />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            options={caseCycleOptions}
            label="Case Cycle"
            name="cycle"
          />
        </Grid>
        <GridDateField
          name="scheduledOn"
          label="Case Schedule Date"
          xs={12}
          md={6}
        />
        <GridDateField
          name="confirmationSentOn"
          label="Confirmation Sent Date"
          xs={12}
          md={12}
        />
        <GridDateField
          name="documentsReceivedOn"
          label="Documents Received Date"
          xs={12}
          md={12}
        />
        <Grid item xs={12} md={12}>
          <AssigneeSelect />
        </Grid>

        <SectionDivider title="Location" />
        <Field as={null}>
          {({ form: { values } }: FieldProps<Case>) => {
            const disabled = values.locationId === subject.locationId;
            const isNew = !values.id;

            return (
              <>
                <Grid item xs={12}>
                  <CheckboxField
                    name="locationId"
                    label="Location Same as Subject"
                    onValue={subject.locationId}
                    undefinedOff={isNew || disabled}
                  />
                </Grid>
                <GridTextField name="location.address" xs={12} disabled={disabled} />
                <GridTextField name="location.city" xs={12} disabled={disabled} />
                <Grid item xs={12} md={6} lg={6}>
                  <UsStateSelect disabled={disabled} name="location.stateAbbr" />
                </Grid>
                <GridTextField
                  name="location.zipCode"
                  disabled={disabled}
                  xs={12}
                  md={6}
                  lg={6}
                />
                <GridTextField
                  label="Parcel ID"
                  name="location.parcelId"
                  disabled={disabled}
                  xs={12}
                />
              </>
            );
          }}
        </Field>

        <ContactFields prefix="primaryContact" title="Primary Contact" />
        <ContactFields prefix="secondaryContact" title="Secondary Contact" />

        <SectionDivider title="Results" />
        <Grid item xs={12}>
          <CaseResultTypeSelect name="caseResultTypeId" label="Result" />
        </Grid>
        <GridDateField
          name="resultsLetterSentOn"
          label="Results Letter Sent On"
          xs={12}
        />
        <GridTextField
          name="resultSummary"
          label="Result Summary"
          xs={12}
        />
        <GridDateField
          name="reviewCompletedOn"
          label="Review Completed On"
          xs={12}
        />
        <Grid item xs={12}>
          <UserSelect name="reviewedByUid" label="Reviewed By" />
        </Grid>

        <SectionDivider title="Fees" />
        <GridTextField name="feeSize" xs={12} />
        <Grid item xs={12}>
          <DecimalField
            name="feeAmount"
            type="number"
            id="feeAmount"
            label="Fee Amount"
            fullWidth
          />
        </Grid>
        <GridDateField name="feeInvoicedOn" xs={12} />
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "420px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={closeDrawer}
          type="reset"
        >
          Cancel
        </Button>
      </Box>
    </Form>
  );
};

export default CaseForm;
