import { FC, useCallback } from "react";
import { Form as FormikForm, useFormikContext } from "formik";
import { Box, Button, Grid } from "@mui/material";

import { TextField } from "auditaware-ui";
import SubjectTypeSelect from "../../shared/Form/SubjectTypeSelect";
import UsStateSelect from "../../shared/Form/UsStateSelect";
import { useDrawer } from "auditaware-ui";
import { DrawerFormTypes } from "../../../constants/forms";

const Form: FC<DrawerFormTypes> = ({ drawerId }) => {
  const { isSubmitting } = useFormikContext();
  const { toggleDrawer } = useDrawer();

  const handleCancelClick = useCallback(() => {
    toggleDrawer(drawerId);
  }, [drawerId, toggleDrawer]);

  return (
    <FormikForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubjectTypeSelect />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="location.parcelId"
            id="location.parcelId"
            label="Parcel ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="externalId"
            id="externalId"
            label="Unique ID"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="contact.primaryName"
            id="contact.primaryName"
            label="Primary Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="contact.secondaryName"
            id="contact.secondaryName"
            label="Secondary Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="location.address"
            id="location.address"
            label="Address"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location.city"
            id="location.city"
            label="City"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UsStateSelect name="location.stateAbbr" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location.zipCode"
            id="location.zipCode"
            label="Zip Code"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contact.phoneNumber"
            id="contact.phoneNumber"
            label="Phone Number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location.countyName"
            id="location.countyName"
            label="County Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="location.jurisdictionName"
            id="location.jurisdictionName"
            label="Jurisdiction Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="contact.email"
            id="contact.email"
            label="Email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="contact.website"
            id="contact.website"
            label="Website"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="firstAuditYear"
            id="firstAuditYear"
            label="First Year Open"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="contact.naics"
            id="contact.naics"
            label="NAICS"
            fullWidth
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "fixed", // Set position to fixed
          bottom: 0, // Fixed at the bottom
          right: 0,
          width: "420px",
          borderTop: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          padding: ".5rem",
          zIndex: 1,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="success"
          disabled={isSubmitting}
          fullWidth={true}
        >
          Save
        </Button>
        <Button
          type="reset"
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isSubmitting}
          fullWidth={true}
          sx={{ ml: 2 }}
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </Box>
    </FormikForm>
  );
};

export default Form;
