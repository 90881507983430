import { useQuery, useLazyQuery, useMutation, FetchResult } from "@apollo/client";
import { gql } from "graphql-tag";
import { pick } from "lodash";

import { Case, CaseInput } from "auditaware-types";
import { useOrganization } from "auditaware-auth";

import { APIMultiError, SaveCaseError } from "../lib/errors";

type GetCasesResponse = {
  organization: {
    cases: Case[];
  };
};

type GetSubjectCasesResponse = {
  organization: {
    subject: {
      cases: Case[];
    };
  };
};

type GetCaseResponse = {
  organization: {
    case: Case;
  };
};

type CreateCaseForSubjectResponse = {
  createCaseForSubject: Case;
};

type UpdateCaseResponse = {
  updateCase: Case;
};

const casesFragment = gql`
  fragment CasesFragment on Case {
    id
    subjectId
    organizationId
    locationId
    status
    caseType
    cycle
    scheduledOn
    createdByUid
    assigneeUid
    createdAt
    updatedAt

    confirmationSentOn
    documentsReceivedOn

    caseResultTypeId
    resultSummary
    resultsLetterSentOn
    reviewCompletedOn
    reviewedByUid
    feeSize
    feeAmount
    feeInvoicedOn

    primaryContact {
      primaryName
      secondaryName
      aliasName
      phoneNumber
      email
      address
      city
      stateAbbr
      zipCode
    }

    secondaryContact {
      primaryName
      secondaryName
      aliasName
      phoneNumber
      email
      address
      city
      stateAbbr
      zipCode
    }

    result {
      name
    }

    location {
      id
      parcelId
      address
      city
      stateAbbr
      zipCode
      countyName
      jurisdictionName
    }

    assignee {
      uid
      email
      displayName
    }

    reviewedBy {
      uid
      email
      displayName
    }

    subject {
      id
      externalId
      locationId
      subjectType
      firstAuditYear
      createdByUid
      contact {
        primaryName
      }
      location {
        id
        parcelId
        address
        city
        stateAbbr
        zipCode
      }
    }
  }
`;

const GET_CASES = gql`
  ${casesFragment}
  query GetCases($orgId: ID!) {
    organization(id: $orgId) {
      id
      cases {
        ...CasesFragment
      }
    }
  }
`;

const GET_SUBJECT_CASES = gql`
  ${casesFragment}
  query GetCasesForSubject($orgId: ID!, $subjectId: ID!) {
    organization(id: $orgId) {
      id
      subject(id: $subjectId) {
        id
        cases {
          ...CasesFragment
        }
      }
    }
  }
`;

export const GET_CASE = gql`
  ${casesFragment}
  query GetCase($orgId: ID!, $id: ID!) {
    organization(id: $orgId) {
      id
      case(id: $id) {
        ...CasesFragment
        
        notes {
          id
          createdAt
          updatedAt
          text
          author {
            uid
            email
            displayName
          }
        }

        attachments {
          id
          contentType
          description
          fileName
          createdAt
          url
        }
      }
    }
  }
`;

const CREATE_CASE = gql`
  mutation CreateCase($subjectId: ID!, $input: CaseInput!) {
    createCaseForSubject(subjectId: $subjectId, input: $input) {
      ...CasesFragment
    }
  }
  ${casesFragment}
`;

const UPDATE_CASE = gql`
  mutation UpdateCase($id: ID!, $input: CaseInput!) {
    updateCase(id: $id, input: $input) {
      ...CasesFragment
    }
  }
  ${casesFragment}
`;


export const useCases = () => {
  const org = useOrganization();
  const { error, loading, data } = useQuery<GetCasesResponse>(GET_CASES, {
    variables: { orgId: org.id },
  });

  if (error) {
    // TODO: We do not have a good layer in the UI to handle API errors in a
    // consistent way, so until then this ensures it at least gets logged.
    //
    // https://reasonconsulting.atlassian.net/browse/AA-593
    console.error(error);
  }

  const cases = data?.organization?.cases || [];
  return { error, loading, cases };
};

export const useSubjectCases = (subjectId: string) => {
  const org = useOrganization();
  const { error, loading, data } = useQuery<GetSubjectCasesResponse>(GET_SUBJECT_CASES, {
    variables: { orgId: org.id, subjectId },
  });

  if (error) {
    // TODO: We do not have a good layer in the UI to handle API errors in a
    // consistent way, so until then this ensures it at least gets logged.
    //
    // https://reasonconsulting.atlassian.net/browse/AA-593
    console.error(error);
  }

  const cases = data?.organization?.subject?.cases || [];
  return { error, loading, cases };
};

export const useLoadCase = () => {
  const org = useOrganization();
  const [loadCase, { error, data, ...rest }] = useLazyQuery<GetCaseResponse>(GET_CASE, {
    variables: { orgId: org.id },
  });

  if (error) {
    console.error(error);
  }

  const caseData = data?.organization?.case;
  const resultData = { error, case: caseData, ...rest };
  const result: [typeof loadCase, typeof resultData] = [loadCase, resultData];
  return result;
};

const mutableCaseFields = [
  "status",
  "caseType",
  "cycle",
  "location",
  "locationId",
  "scheduledOn",
  "primaryContact",
  "secondaryContact",
  "assigneeUid",
  "feeSize",
  "feeAmount",
  "feeInvoicedOn",
  "confirmationSentOn",
  "documentsReceivedOn",
  "caseResultTypeId",
  "resultSummary",
  "resultsLetterSentOn",
  "reviewCompletedOn",
  "reviewedByUid",
];

const mutableLocationFields = [
  "address",
  "city",
  "stateAbbr",
  "zipCode",
  "parcelId",
];

export type CaseCleaner =
  <T extends Case | CaseInput>(input: T) => Record<string, any>;

export const defaultCaseCleaner: CaseCleaner = (input) => ({
  ...pick(input, mutableCaseFields),

  // Don't send the `location` argument if it is not present. This happens
  // when the Case and Subject share the same locationId, and we don't want
  // to update the "location" fields that are disabled in the form when the
  // "Location Same as Subject" checkbox is true.
  location: input.location && {
    ...pick(input.location, mutableLocationFields),
  },
});

// afterSave processes the result of an Apollo Mutation and returns:
//   1. The attribute in the result data specified by 'name' as 'data'.
//   2. The raw FetchResult from Apollo.
//
// Any API Errors encountered will be thrown, and an error will also be thrown
// if data does not exist at 'data[name]'.
const afterSave = <
  DataT,
  T extends Record<string, any> = Record<string, any>,
> (name: string) =>
    (result: FetchResult<T>) => {
      if (result.errors) {
        throw new APIMultiError("Error saving Case!", result.errors);
      }

      const data = result?.data?.[name] as DataT;
      if (!data) {
        throw new SaveCaseError(
          "Error saving Case!",
          `Couldn't find attribute "${name}" in GraphQL response data`,
        );
      }

      return { data, result };
    };

export const useSubjectCreateCase = (
  subjectId: string,
  clean: CaseCleaner = defaultCaseCleaner,
) => {
  const [
    createCase,
    { error, data, ...rest },
  ] = useMutation<CreateCaseForSubjectResponse>(CREATE_CASE, {
    variables: { subjectId },
    refetchQueries: [GET_SUBJECT_CASES],
  });

  if (error) {
    console.error(error);
  }

  const createFn = (input: CaseInput) => {
    // Wrap the mutate function to ensure we only send the fields we want to
    // create.
    const variables = { input: clean(input) };
    return createCase({ variables })
      .then(afterSave<Case>("createCaseForSubject"));
  };

  const caseData = data?.createCaseForSubject;
  const resultData = { error, case: caseData, ...rest };
  const result: [typeof createFn, typeof resultData] = [createFn, resultData];
  return result;
};

export const useUpdateCase = (
  caseId?: string,
  clean: CaseCleaner = defaultCaseCleaner,
) => {
  const [
    updateCase,
    { error, data, ...rest },
  ] = useMutation<UpdateCaseResponse>(UPDATE_CASE, {
    variables: { id: caseId },
  });

  if (error) {
    console.error(error);
  }

  const updateFn = (input: Case) => {
    // Wrap the mutate function to ensure we only send the fields we want to
    // update.
    const variables = { input: clean(input) };
    return updateCase({ variables })
      .then(afterSave<Case>("updateCase"));
  };

  const caseData = data?.updateCase;
  const resultData = { error, case: caseData, ...rest };
  const result: [typeof updateFn, typeof resultData] = [updateFn, resultData];
  return result;
};
