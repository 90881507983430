import { FC } from "react";
import SelectField, { SelectFieldProps } from "../../shared/Form/SelectField";
import { usStateSelectOptions } from "auditaware-types";

type UsStateSelectProps = Partial<Omit<SelectFieldProps, "label" | "type" | "options">>;

const UsStateSelect: FC<UsStateSelectProps> = ({
  name,
  ...props
}) => {
  const blank = { value: "", display: "-- Select --" };

  return (
    <SelectField
      { ...props }
      name={name || "stateAbbr"}
      label="State"
      options={[blank, ...usStateSelectOptions]}
    />
  );
};

export default UsStateSelect;
