import { FC } from "react";
import { useParams } from "react-router-dom";
import { Grid, Box, Fab } from "@mui/material";
import Add from "@mui/icons-material/Add";

import { Drawer, useDrawerControls } from "auditaware-ui";

import StyledPaper from "../../shared/StyledPaper";
import { useSubjectCases } from "../../../hooks/cases";
import { DataGrid } from "../../shared/DataGrid";
import CreateCase from "../Case/CreateCase";

import { caseColumns } from "./dataGridColumns";

const Cases: FC = () => {
  const { subjectId = "" } = useParams();
  const { error, loading, cases } = useSubjectCases(subjectId);
  const [
    drawerProps,
    openDrawer,
    closeDrawer,
  ] = useDrawerControls({ title: "Create Case" });

  if (loading || error) return null;

  return (
    <Grid item xs md={12} lg={12}>
      <StyledPaper>
        <Box mb={3}>
          <DataGrid
            title="Cases"
            rows={cases}
            columns={caseColumns}
            headerHeight={30}
            settingsPrefix="cases"
            filtersKey="subjectCases.filters"
          />
        </Box>
      </StyledPaper>
      <Fab
        sx={{ position: "fixed", bottom: "2rem", right: "6rem" }}
        color="primary"
        aria-label="add case"
        onClick={openDrawer}
        title="Add Case"
        type="button"
      >
        <Add />
      </Fab>
      <Drawer {...drawerProps}>
        <CreateCase closeDrawer={closeDrawer}/>
      </Drawer>
    </Grid>
  );
};

export default Cases;
