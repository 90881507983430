import { FC } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";

import CaseForm from "./CaseForm";
import { CaseStatus, Subject, CaseSchema as validationSchema } from "auditaware-types";
import { useSubjectCreateCase } from "../../../hooks/cases";
import { useSubject } from "../../../hooks/subjectHooks";
import useHandleSubmit from "./useHandleSubmit";

export type CreateCaseProps = {
  closeDrawer: () => void;
}

type InnerFormProps = CreateCaseProps & {
  subject: Subject;
}

const initialValues = {
  name: "",
  assigneeUid: "",
  status: CaseStatus.New,
  caseType: "",
  cycle: null,
  scheduledOn: null,
  feeSize: "",
  feeInvoicedOn: null,
  feeAmount: 0,
  locationId: "",
  primaryContact: {
    primaryName: "",
    secondaryName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    stateAbbr: "",
    zipCode: "",
  },
  secondaryContact: {
    primaryName: "",
    secondaryName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    stateAbbr: "",
    zipCode: "",
  },
};

const InnerForm: FC<InnerFormProps> = ({ subject, closeDrawer }) => {
  const [createCase] = useSubjectCreateCase(subject.id);
  const handleSubmit = useHandleSubmit(createCase, subject, closeDrawer);

  const initialValuesWithLocation = {
    ...initialValues,
    locationId: subject.locationId,
    location: subject.location,
  };

  return (
    <Formik
      initialValues={initialValuesWithLocation}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <CaseForm
        closeDrawer={closeDrawer}
        subject={subject}
      />
    </Formik>
  );
};

const CreateCase: FC<CreateCaseProps> = (props) => {
  const { subjectId = "" } = useParams();
  const { loading: loadingSubject, data: subjectData } = useSubject(subjectId);

  const subject = subjectData?.organization?.subject;
  if (!subject || loadingSubject) return null;

  return <InnerForm subject={subject} {...props}/>;
};

export default CreateCase;
