import { FC } from "react";
import SelectField, { SelectFieldProps } from "./SelectField";

export const options = ["ESA", "Discovery", "Audit", "Inspection", "Review", "Appraisal"];

const CaseTypeSelect: FC<Omit<SelectFieldProps, "name" | "label">> = ({
  required = false,
  id,
  autoComplete,
}) => {
  const name = "caseType";
  const label = "Case Type";

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

export default CaseTypeSelect;
