import { FC } from "react";
import SelectField, { SelectFieldProps } from "./SelectField";

export const options = [
  "Organization",
  "Individual",
  "Residence",
];

const SubjectTypeSelect: FC<Omit<SelectFieldProps, "name" | "label">> = ({
  required = false,
  id,
  autoComplete,
}) => {
  const name = "subjectType";
  const label = "Subject Type";

  const props = {
    name,
    required,
    id,
    label,
    autoComplete,
    options,
  };
  return <SelectField {...props} />;
};

export default SubjectTypeSelect;
