import { Formik } from "formik";
import { FC } from "react";

import {
  Case,
  Subject,
  CaseSchema as validationSchema,
} from "auditaware-types";

import CaseForm from "./CaseForm";
import { useUpdateCase } from "../../../hooks/cases";
import useHandleSubmit from "./useHandleSubmit";

type EditProps = {
  caseData: Case;
  subject: Subject;
  closeDrawer: () => void;
};

const EditCase: FC<EditProps> = ({ caseData, subject, closeDrawer }) => {
  const caseId = caseData.id;
  const [updateCase] = useUpdateCase(caseId);
  const handleSubmit = useHandleSubmit(
    updateCase,
    subject,
    closeDrawer,
  );

  if (!caseData) return null;

  return (
    <Formik
      initialValues={caseData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <CaseForm
        closeDrawer={closeDrawer}
        subject={subject}
      />
    </Formik>
  );
};

export default EditCase;
